import React, { useState } from 'react'
import styles from './expand.module.scss'

interface Props {
  children: string
}

export default function Expand({ children }: Props) {
  const [show, setShow] = useState(false)

  return (
    <>
      <div>{children.substr(0, show ? children.length : 50)}</div>
      <span className={styles.expand} onClick={() => setShow(!show)}>{show ? 'Read Less' : 'Read More'}</span>
    </>
  )
}