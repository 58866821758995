import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js'
import styles from './spider-chart.module.scss'

interface Props {
  labels: string[],
  levels: number[],
  title: string
}

export default function SpiderChart({ labels, levels, title }: Props) {
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (ref.current) {
      const ctx = ref.current.getContext('2d');
  
      new Chart(ctx, {
        type: 'radar',
        data: {
            labels: labels,
            datasets: [{
                label: title,
                data: levels,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }]
        },
        options: {
          scale: {
            angleLines: {
              display: true
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10
            }
          }
        }
      });
    }

  }, [ref.current])
  

  return (
    <canvas ref={ref} className={styles.canvas} width="400" height="300" />
  )
}