import React from 'react'
import classNames from 'classnames'
import styles from './table.module.scss'

export interface TRProps {
  children: React.ReactNode
}

export function TR({ children }: TRProps) {
  return <div className={styles.tableRow}>
    {children}
  </div>
}

export interface THProps {
  children: React.ReactNode
  flex?: number
}

export function TH({ children, flex }: THProps) {
  return <div className={classNames(styles.tableData, styles.tableHeader)} style={{ flex }}>
    {children}
  </div>
}

export interface TDProps {
  children: React.ReactNode
  flex?: number
}

export function TD({ children, flex }: TDProps) {
  return <div className={styles.tableData} style={{ flex }}>
    {children}
  </div>
}

export interface TableProps {
  children: React.ReactNode
}

export function Table({ children }: TableProps) {
  return <div className={styles.table}>
    {children}
  </div>
}