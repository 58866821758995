import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import SpiderChart from '../components/spider-chart/spider-chart'
import { Table, TR, TH, TD } from '../components/table/table'
import Expand from '../components/expand/expand'
import styles from './resume.module.scss'

const ResumePage = () => (
  <Layout>
    <SEO title="Yang | Resume" />
    <h1>Resume</h1>
    <p>You can find my resume here</p>

    <section className={styles.section}>
      <h2>Introduction</h2>
      <p>I am a technology lead with a diverse range of experience. I have experience in both UI and backend development and I am a pusher for Agile and DevOps practices. I have strong understanding and experience of a wide range of technology, from UI to Backend and Database. Aside from technology, I am a natural leader who is extremely outspoken. For a technology leader I am also deeply involved in the business, I have a wide range of different industry knowledge. I am a strong believer that the best products can only be created when the development team creating it are working closely with business and marketing teams. I am someone with a lot of ideas and also will push hard for changes.</p>
    </section>

    <section className={styles.section}>
      <h2>Skills</h2>

      <div className={styles.row}>
        <div className={styles.skillChart}>
          <SpiderChart title="Skill Level" labels={['Web Frontend', 'Backend', 'Mobile', 'Database', 'DevOps', 'Security', 'Blockchain', 'Tech Management']} levels={[9, 8, 4, 6, 8, 7, 7, 7]} />
        </div>
        <div className={styles.skillChart}>
          <SpiderChart title="Frontend" labels={['React', 'Angular', 'Vue', 'Svelte', 'Vanilla', 'jQuery']} levels={[9, 5, 5, 4, 8, 7]} />
        </div>
        <div className={styles.skillChart}>
          <SpiderChart title="Backend" labels={['Express.js', 'Nest.js', 'Spring Boot']} levels={[9, 7, 6]} />
        </div>
      </div>
    </section>

    <section className={styles.section}>
      <h2>Education</h2>

      <p>
        Bachelor of Commerce in Finance and Actuarial Studies (University of New South Wales)
      </p>
    </section>

    <section className={styles.section}>
      <h2>Experience</h2>

      <Table>
        <TR>
          <TH>Period</TH>
          <TH>Company</TH>
          <TH>Role</TH>
          <TH flex={5}>Responsbilities</TH>
        </TR>
        <TR>
          <TD>20 Oct - Present</TD>
          <TD><a href="http://crypto.com/" target="_blank" rel="noreferrer noopener">Crypto.com</a></TD>
          <TD>Tech Lead</TD>
          <TD flex={5}>TBC</TD>
        </TR>
        <TR>
          <TD>Jul 18 - Sep 20</TD>
          <TD><a href="https://www.sc.com/hk/" target="_blank" rel="noreferrer noopener">Standard Chartered</a></TD>
          <TD>Tech Lead</TD>
          <TD flex={5}><Expand>Managed a team of UI developers, we worked on a high traffic FX platform. I built a community of frontend developers within the bank and focused on using React. I created several component and frontend libraries used by multiple teams to help speed up development. I spearheaded the transition from Selenium to Cypress end to end testing. I promoted full stack development and ran a course to upgrade those willing into full stack development.</Expand></TD>
        </TR>
        <TR>
          <TD>Aug 16 - Jun 18</TD>
          <TD><a href="https://www.axa.com.hk/en" target="_blank" rel="noreferrer noopener">Axa</a></TD>
          <TD>Team Lead</TD>
          <TD flex={5}><Expand>Managing a scrum a team, taking on both a role of a scrum master and a tech lead. I introduced many Agile ways of doing things in AXA and helped bring the wider team together working. As the tech lead, I introduced many new practices and progressive technologies to the company. We adopted GraphQL, NPM Packages, extensive NodeJS and ReactJS use and Docker + Openshift. My greatest achievement at AXA was building the development community and helping developers being more productive.</Expand></TD>
        </TR>
        <TR>
          <TD>Dec 15 - Aug 16</TD>
          <TD><a href="https://dius.com.au/" target="_blank" rel="noreferrer noopener">Dius Computing</a></TD>
          <TD>Senior Developer</TD>
          <TD flex={5}>Javascript Consultant, working on React Components, Node Microservices and AWS</TD>
        </TR>
        <TR>
          <TD>Sep 15 - Dec 15</TD>
          <TD><a href="https://generalassemb.ly/" target="_blank" rel="noreferrer noopener">General Assembly</a></TD>
          <TD>Part-time instructor</TD>
          <TD flex={5}>I was the instructor for a front end web development course</TD>
        </TR>
        <TR>
          <TD>Jun 15 - Dec 15</TD>
          <TD><a href="https://www.bauermedia.com/" target="_blank" rel="noreferrer noopener">Bauer Media</a></TD>
          <TD>Senior Developer</TD>
          <TD flex={5}>Enabling digital content on the web through isomorphic javascript, React/Node. I worked on the platforms team, building the engine that runs Bauer's websites.
      optimised pagespeed score from 40 - 75
      Built multiple NPM libraries packages</TD>
        </TR>
        <TR>
          <TD>Dec 14 - May 15</TD>
          <TD><a href="https://nextgen.net/#!/home" target="_blank" rel="noreferrer noopener">Nextgen</a></TD>
          <TD>Developer</TD>
          <TD flex={5}>Part of the product innovation team working on new changes to the flagship product ApplyOnline using the latest technologies. Also working on building mobile app product.</TD>
        </TR>
        <TR>
          <TD>Jul 13 - Dec 14</TD>
          <TD><a href="https://www.ibm.com/au-en" target="_blank" rel="noreferrer noopener">IBM</a></TD>
          <TD>Developer</TD>
          <TD flex={5}>Developed 33 different online application forms for St. George Bank as IBM's client using BootstrapCSS and AngularJS. Also automated a number of spreadsheets which are still being used as of today.</TD>
        </TR>
        <TR>
          <TD>09 - Jul 13</TD>
          <TD>Miscellaneous</TD>
          <TD>Developer, Instructor</TD>
          <TD flex={5}>Various Jobs throughout my university years, including Maths tutor, Programming Instructor and internship at IBM.</TD>
        </TR>
      </Table>
    </section>
    
    <section className={styles.section}>
      <h2>Languages</h2>
      <ul>
        <li>English (Native)</li>
        <li>Mandarin (Fluent for conversations)</li>
      </ul>
    </section>
    
    <section className={styles.section}>
      <h2>Other Links</h2>
      <ul>
        <li>Github</li>
        <li>Stack Overflow</li>
        <li>LinkedIn</li>
        <li>Twitter</li>
      </ul>
    </section>
  </Layout>
)

export default ResumePage
